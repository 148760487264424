import create from "zustand";
import {
  PhantomWalletAdapter,
  SolletWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { PublicKey } from "@solana/web3.js";

export interface AuthStoreState {
  pickingWallet: boolean;
  adapter?: PhantomWalletAdapter | SolletWalletAdapter;
}

export interface AuthStoreMethods {
  getWallet: () => PublicKey | undefined | null;
}

export const useAuthStore = create<AuthStoreState & AuthStoreMethods>(
  (set, get) => ({
    pickingWallet: false,
    getWallet() {
      return get().adapter?.publicKey;
    },
  })
);
