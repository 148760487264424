import GemFarmIdl from '../anchor/idl/gem_farm.json';
import GemBankIdl from '../anchor/idl/gem_bank.json';
import {
  ConfirmOptions,
  Connection,
  ConnectionConfig,
  LAMPORTS_PER_SOL,
  PublicKey,
  sendAndConfirmRawTransaction,
  sendAndConfirmTransaction,
  Signer,
  SystemProgram,
  Transaction,
  TransactionInstruction,
  TransactionSignature,
} from '@solana/web3.js';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import {
  BN,
  Program,
  Provider,
  splitArgsAndCtx,
  web3,
} from '@project-serum/anchor';

import config from './config';
import { AccountUtils } from './account-utils';
import { WalletContextState } from '@solana/wallet-adapter-react';
import { NFT } from './nfts';
import { programs } from '@metaplex/js';
import Axios from 'axios';
import { Collection } from './use-request';

import { TierConfig } from './nfts';
import { SendTxRequest } from '@project-serum/anchor/dist/cjs/provider';
import collection from './collection';

const { Keypair, SYSVAR_RENT_PUBKEY, SOLANA_SCHEMA } = web3;

export const RewardType = {
  Variable: { variable: {} },
  Fixed: { fixed: {} },
};

export enum WhitelistType {
  Creator = 1 << 0,
  Mint = 1 << 1,
}

export interface VariableRateConfig {
  amount: BN;
  durationSec: BN;
}

export interface TierConfigForm {
  rewardRate: string;
  requiredTenure: string;
}

export interface CreateFarmForm {
  candyMachineId: string;
  fundAmount: string;
  rewardAMint: string;
  paperHandsTaxLamp: string | null;
  rewardAFixedRewardSchedule: {
    tier0: TierConfigForm;
    tier1: TierConfigForm | null;
    tier2: TierConfigForm | null;
    tier3: TierConfigForm | null;
  };
}

export interface CreateFarmData {
  candyMachineId: PublicKey;
  fundAmount: BN;
  rewardAMint: PublicKey;
  paperHandsTaxLamp: BN | null;
  rewardAFixedRewardSchedule: {
    tier0: TierConfig;
    tier1: TierConfig | null;
    tier2: TierConfig | null;
    tier3: TierConfig | null;
    denominator: BN;
  };
}

export interface FarmAccount {
  publicKey: PublicKey;
  address: string;
  bank: PublicKey;
  bankAddress: string;
  vaultCount: BN;
  allocationPerGem?: BN;
  hasPaperHandsTax?: boolean;
  paperHandsTaxInSol?: string;
  variableRate: {
    rewardRate: number;
    rewardEndTs: BN;
  };
  config: {
    paperHandsTaxLamp: BN;
  };
  rewardA: {
    rewardMint: PublicKey;
    fixedRate: {
      schedule: {
        tier0: TierConfig;
        tier1: TierConfig | null;
        tier2: TierConfig | null;
        tier3: TierConfig | null;
      };
    };
  };
  rewardB: {
    rewardMint: PublicKey;
  };
}

export interface FarmerAccount {
  farm: PublicKey;
  farmerVaults: any[];
  vault: PublicKey;
  address: string;
  gemsStaked: BN;
  rewardA: {
    accruedReward: BN;
    paidOutReward: BN;
    variableRate: {
      lastRecordedAccruedRewardPerRarityPoint: { n: BN };
    };
  };
}

export interface StakeNftForm {
  bank: PublicKey;
  farm: PublicKey;
  vault: PublicKey;
  mint: PublicKey;
  source?: PublicKey;
  creator: PublicKey;
}

export function fetchCollections(candyMachine: string) {
  return Axios.post<{
    collections: Collection[];
  }>('/api/get-collections', {
    candyMachine,
  });
}

export function persistNewFarm(form: {
  farmAddress: string;
  collectionId: string;
  rewardMint: string;
}) {
  return Axios.post('/api/create-farm', form);
}

export class GemFarm extends AccountUtils {
  public farmProgram: Program;
  private provider: Provider;
  private connection: Connection;
  private nft: NFT;

  constructor(private wallet: WalletContextState) {
    super();
    const opts: ConnectionConfig = {
      commitment: 'processed',
      confirmTransactionInitialTimeout: 120000
    };

    const confirmOps: ConfirmOptions = {
      commitment: 'processed',
      maxRetries: 3
    }

    this.connection = new Connection(config.QUICKNODE_CLUSTER_URL, opts);
    this.provider = new Provider(this.connection, wallet as any, confirmOps);
    this.nft = new NFT(this.wallet);

    this.farmProgram = new Program(
      GemFarmIdl as any,
      config.FARM_PROGRAM_ID,
      this.provider
    );
  }

  async updateFarm(
    farm: PublicKey,
    whitelistedCandyMachine?: PublicKey,
    paperHandsTaxLamp?: BN
  ) {
    const manager = this.wallet.publicKey!;

    const txSig = await this.farmProgram.rpc.updateFarm(
      {
        whitelistedCandyMachine: whitelistedCandyMachine
          ? whitelistedCandyMachine
          : null,
        paperHandsTaxLamp: paperHandsTaxLamp ? paperHandsTaxLamp : new BN(0),
      },
      null,
      {
        accounts: {
          farm,
          farmManager: manager,
        },
        signers: [],
      }
    );

    return {
      txSig,
    };
  }

  async createFarm({
    candyMachineId,
    fundAmount,
    rewardAMint,
    rewardAFixedRewardSchedule,
    paperHandsTaxLamp,
  }: CreateFarmData) {
    const farm = Keypair.generate();
    const manager = this.wallet.publicKey!;

    const rewardType = RewardType.Fixed; // We'll only reward with variable for now.

    const [farmAuth, farmAuthBump] = await this.findFarmAuthorityPDA(
      farm.publicKey
    );
    const [farmTreasury, farmTreasuryBump] = await this.findFarmTreasuryPDA(
      farm.publicKey
    );
    const [rewardAPot, rewardAPotBump] = await this.findRewardsPotPDA(
      farm.publicKey,
      rewardAMint
    );

    const signers = [farm];

    const initFarmTxSig = await this.farmProgram.rpc.initFarm(
      farmAuthBump,
      farmTreasuryBump,
      rewardAPotBump,
      rewardType,
      rewardAFixedRewardSchedule,
      {
        paperHandsTaxLamp: paperHandsTaxLamp ? paperHandsTaxLamp : new BN(0),
        whitelistedCandyMachine: candyMachineId,
      },
      {
        accounts: {
          farm: farm.publicKey,
          farmManager: manager,
          farmAuthority: farmAuth,
          farmTreasury,
          payer: manager,
          rewardAPot,
          rewardAMint,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        },
        signers,
      }
    );

    console.log('@initFarmTxSig', initFarmTxSig);

    const authorizeFunderTransactionInstruction =
      await this.getAuthorizeFunderInstruction(
        farm.publicKey,
        this.wallet.publicKey!
      );

    console.log(
      '@authorizeFunderTransactionInstruction',
      authorizeFunderTransactionInstruction
    );

    const fundRewardTransactionInstruction =
      await this.getFundRewardTransactionInstruction(
        farm.publicKey,
        fundAmount,
        rewardAMint
      );

    console.log(
      '@fundRewardTransactionInstruction',
      fundRewardTransactionInstruction
    );

    const transaction = new Transaction();

    transaction.add(authorizeFunderTransactionInstruction);
    transaction.add(fundRewardTransactionInstruction);

    const txSig = await this.provider.send(transaction);

    console.log('@txSig', txSig);

    return {
      txSig,
      farm,
    };
  }

  async fundReward(farm: PublicKey, rewardMint: PublicKey, amount: BN) {
    const funder = this.wallet.publicKey!;

    const rewardSource = await this.findATA(rewardMint, funder);
    const [farmAuth, farmAuthBump] = await this.findFarmAuthorityPDA(farm);
    const [authorizationProof, authorizationProofBump] =
      await this.findAuthorizationProofPDA(farm, funder);
    const [pot, potBump] = await this.findRewardsPotPDA(farm, rewardMint);

    console.log(
      'funding reward pot',
      pot.toBase58(),
      ' with ',
      amount.toNumber(),
      ' tokens',
      'on farm',
      farm.toBase58()
    );
    const txSig = await this.farmProgram.rpc.fundReward(
      authorizationProofBump,
      potBump,
      amount,
      {
        accounts: {
          farm,
          authorizationProof,
          authorizedFunder: funder,
          rewardPot: pot,
          rewardSource,
          rewardMint,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
        },
        signers: [],
      }
    );

    return {
      farmAuth,
      farmAuthBump,
      authorizationProof,
      authorizationProofBump,
      pot,
      potBump,
      txSig,
      rewardMint,
    };
  }

  async findFarmAuthorityPDA(farm: PublicKey) {
    return this.findProgramAddress(this.farmProgram.programId, [farm]);
  }

  async findFarmTreasuryPDA(farm: PublicKey) {
    return this.findProgramAddress(this.farmProgram.programId, [
      'treasury',
      farm,
    ]);
  }

  async findRewardsPotPDA(farm: PublicKey, rewardMint: PublicKey) {
    return this.findProgramAddress(this.farmProgram.programId, [
      'reward_pot',
      farm,
      rewardMint,
    ]);
  }

  async findAuthorizationProofPDA(farm: PublicKey, funder: PublicKey) {
    return this.findProgramAddress(this.farmProgram.programId, [
      'authorization',
      farm,
      funder,
    ]);
  }

  async findWhitelistProofPDA(farm: PublicKey, whitelistedAddress: PublicKey) {
    return this.findProgramAddress(this.farmProgram.programId, [
      'whitelist',
      farm,
      whitelistedAddress,
    ]);
  }

  async getAuthorizeFunderInstruction(farm: PublicKey, funder: PublicKey) {
    const [authorizationProof, authorizationProofBump] =
      await this.findAuthorizationProofPDA(farm, funder);

    return this.farmProgram.instruction.authorizeFunder(
      authorizationProofBump,
      {
        accounts: {
          farm,
          farmManager: this.wallet.publicKey!,
          funderToAuthorize: funder,
          authorizationProof,
          systemProgram: SystemProgram.programId,
        },
        signers: [],
      }
    );
  }

  async fetchFarmAcc(farm: PublicKey) {
    return this.farmProgram.account.farm.fetch(farm);
  }

  async fetchAllFarmPDAs(manager?: PublicKey): Promise<FarmAccount[]> {
    const filter = manager
      ? [
          {
            memcmp: {
              offset: 10, //need to prepend 8 bytes for anchor's disc
              bytes: manager.toBase58(),
            },
          },
        ]
      : [];
    const pdas = await this.farmProgram.account.farm.all(filter);

    return pdas.map(
      (pda) =>
        ({
          ...pda.account,
          publicKey: pda.publicKey,
          address: pda.publicKey?.toBase58(),
          bank: pda.account?.bank,
          bankAddress: pda?.account?.bank?.toBase58(),
          gemsStaked: pda?.account?.gemsStaked,
          variableRate: {
            rewardRate: (pda?.account?.rewardA?.variableRate?.rewardRate?.n /
              10 ** 15) as any,
            rewardEndTs: pda?.account?.rewardA?.times?.rewardEndTs,
          },
        } as any)
    );
  }

  public async getFundRewardTransactionInstruction(
    farm: PublicKey,
    fundAmount: BN,
    rewardMint: PublicKey
  ) {
    const rewardSource = await this.findATA(rewardMint, this.wallet.publicKey!);

    const [authorizationProof, authorizationProofBump] =
      await this.findAuthorizationProofPDA(farm, this.wallet.publicKey!);
    const [pot, potBump] = await this.findRewardsPotPDA(farm, rewardMint);

    return this.farmProgram.instruction.fundReward(
      authorizationProofBump,
      potBump,
      fundAmount,
      {
        accounts: {
          farm: farm,
          authorizationProof,
          authorizedFunder: this.wallet.publicKey!,
          rewardPot: pot,
          rewardSource,
          rewardMint,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
        },
        signers: [],
      }
    );
  }

  public findFarmerPDA(farm: PublicKey) {
    return this.findProgramAddress(this.farmProgram.programId, [
      'farmer',
      farm,
      this.wallet.publicKey!,
    ]);
  }

  public async fetchFarmerAcc(farm: PublicKey) {
    const [farmerPDA] = await this.findFarmerPDA(farm);

    return this.farmProgram.account.farmer.fetch(farmerPDA);
  }

  public async fetchAllVaultAccountsForFarmAndOwner(farm: PublicKey) {
    const accountVaults = await this.farmProgram.account.vault.all([
      {
        memcmp: {
          offset: 8, //need to prepend 8 bytes for anchor's disc, then bytes 8 - 40 are for the farm
          bytes: farm.toBase58(),
        },
      },
      {
        memcmp: {
          offset: 40, //need to prepend 8 bytes for anchor's disc, then another 32 for the farm key. the next 40 - 72 bytes are for the vault owner
          bytes: this.wallet?.publicKey?.toBase58()!,
        },
      },
    ]);

    const accountVaultsWithNFTs = await Promise.all(
      accountVaults.map((token) =>
        this.nft.getNftMetadata(token.account.gemMint)
      )
    );

    return accountVaultsWithNFTs.map((nft, idx) => ({
      ...nft,
      ...nft?.externalMetadata,
      gemMint: accountVaults[idx]?.account?.gemMint,
      vault: accountVaults[idx],
    })) as any[];
  }

  public async initVault(farm: PublicKey, gemMint: PublicKey) {
    const owner = this.wallet.publicKey!;
    const [vault, vaultBump] = await this.findVaultPDA(farm, owner, gemMint);

    console.log(
      'creating vault at',
      vault.toBase58(),
      ' for farm ',
      farm.toBase58()
    );
    return this.farmProgram.instruction.initVault(vaultBump, {
      accounts: {
        farm,
        vault,
        gemMint,
        owner,
        payer: owner,
        systemProgram: SystemProgram.programId,
      },
      signers: [],
    });
  }

  public async withdrawNft(
    farm: PublicKey,
    gemMint: PublicKey,
    rewardAMint: PublicKey
  ) {
    const owner = this.wallet.publicKey!;

    const [vault] = await this.findVaultPDA(farm, owner, gemMint);
    const [gemBox, gemBoxBump] = await this.findGemBoxPDA(vault);
    const [farmAuth, farmAuthBump] = await this.findFarmAuthorityPDA(farm);
    const [vaultAuth, vaultAuthBump] = await this.findVaultAuthorityPDA(vault);
    const gemDestination = await this.findATA(gemMint, owner);
    const [farmTreasury, farmTreasuryBump] = await this.findFarmTreasuryPDA(
      farm
    );
    const [rewardAPot, rewardAPotBump] = await this.findRewardsPotPDA(
      farm,
      rewardAMint
    );
    const rewardADestination = await this.findATA(rewardAMint, owner);

    console.log(
      `withdrawing 1 gem from vault ${vault} (in gembox ${gemBox.toBase58()}) on farm ${farm}`
    );

    const txSig = await this.farmProgram.rpc.withdrawGem(
      farmAuthBump,
      farmTreasuryBump,
      vaultAuthBump,
      gemBoxBump,
      rewardAPotBump,
      {
        accounts: {
          farm,
          gemMint,
          gemBox,
          vault,
          farmTreasury,
          rewardADestination,
          rewardAMint,
          farmAuthority: farmAuth,
          rewardAPot,
          owner,
          authority: vaultAuth,
          gemDestination,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        },
        signers: [],
      }
    );

    return {
      txSig,
      gemBoxBump,
      gemBox,
      gemDestination,
    };
  }

  public async claimRewards(
    farm: PublicKey,
    gemMints: PublicKey[]
  ) {
    const transactions: Transaction[] = []

    const rewardAMint = new PublicKey(collection.rewardMint)

    for (let index = 0; index < gemMints.length; index++) {
      const mint: PublicKey = gemMints[index];

      transactions.push(
        new Transaction().add(await this.getClaimRewardsInstruction(farm, rewardAMint, mint))
      )
      console.log(`claiming rewards for ${mint} vaults`);
    }

    const results = await this.customSendAll(transactions.map((tx) => ({
      tx,
      signers: [],
    })))

    console.log('Claim results', results)

    return results
  }

  public async getClaimRewardsInstruction(
    farm: PublicKey,
    rewardAMint: PublicKey,
    gemMint: PublicKey
  ) {
    const owner = this.wallet.publicKey!;
    const [farmAuth, farmAuthBump] = await this.findFarmAuthorityPDA(farm);
    const [vault, vaultBump] = await this.findVaultPDA(farm, owner, gemMint);

    const [potA, potABump] = await this.findRewardsPotPDA(farm, rewardAMint);

    const rewardADestination = await this.findATA(rewardAMint, owner);

    return this.farmProgram.instruction.claimRewards(
      farmAuthBump,
      vaultBump,
      potABump,
      {
        accounts: {
          farm,
          farmAuthority: farmAuth,
          vault,
          gemMint,
          owner,
          rewardAPot: potA,
          rewardAMint,
          rewardADestination,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        },
        signers: [],
      }
    );
  }

  public async getDepositNftTransaction(farm: PublicKey, gemMint: PublicKey) {
    const owner = this.wallet.publicKey!;
    const [vault] = await this.findVaultPDA(farm, owner, gemMint);
    const [gemBox, gemBoxBump] = await this.findGemBoxPDA(vault);
    const [vaultAuth, vaultAuthBump] = await this.findVaultAuthorityPDA(vault);

    const gemSource = await this.findATA(gemMint, owner);

    const initVaultInstruction = await this.initVault(farm, gemMint);

    const metadata = await programs.metadata.Metadata.getPDA(gemMint);

    const remainingAccounts = [
      {
        pubkey: metadata,
        isWritable: false,
        isSigner: false,
      },
    ];

    console.log(
      `depositing 1 gems into vault: ${vault.toBase58()} into gem box ${gemBox}  on farm: ${farm.toBase58()}`
    );
    const depositGemInstruction = this.farmProgram.instruction.depositGem(
      vaultAuthBump,
      gemBoxBump,
      null,
      {
        accounts: {
          vault,
          farm,
          owner,
          gemSource,
          gemBox,
          gemMint,
          authority: vaultAuth,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        },
        remainingAccounts,
        signers: [],
      }
    );

    const transaction = new Transaction();
    transaction.add(initVaultInstruction);
    transaction.add(depositGemInstruction);

    return {
      transaction,
      vault,
      vaultAuth,
      vaultAuthBump,
      initVaultInstruction,
      depositGemInstruction,
      gemBox,
      gemBoxBump,
    };
  }

  public async bulkDepositNfts(farm: PublicKey, gemMints: PublicKey[]) {
    const allTransactions: Transaction[] = [];

    for (let index = 0; index < gemMints.length; index++) {
      const gemMint = gemMints[index];

      const transaction = new Transaction();

      const { initVaultInstruction, depositGemInstruction } =
        await this.getDepositNftTransaction(farm, gemMint);

      transaction.add(initVaultInstruction).add(depositGemInstruction);

      allTransactions.push(transaction);
    }

    const txSigs = await this.customSendAll(
      allTransactions.map((tx) => ({
        tx,
        signers: [],
      }))
    );

    // first we'll generate a transaction to sign the init vault instructions
    // second we'll generate a transaction to sign the deposit nft instructions

    // we'll chunk these into batches of 50
    return {
      txSigs,
    };
  }

  public async depositNft(farm: PublicKey, gemMint: PublicKey) {
    const { transaction, vault, vaultAuthBump, gemBox, vaultAuth, gemBoxBump } =
      await this.getDepositNftTransaction(farm, gemMint);

    const txSig = await this.provider.send(transaction);

    return {
      vaultAuth,
      vaultAuthBump,
      gemBox,
      vault,
      farm,
      gemBoxBump,
      txSig,
      vaultAccount: { publicKey: vault },
    };
  }

  async findGemBoxPDA(vault: PublicKey) {
    return this.findProgramAddress(this.farmProgram.programId, [
      'gem_box',
      vault,
    ]);
  }

  async findVaultAuthorityPDA(vault: PublicKey) {
    return this.findProgramAddress(this.farmProgram.programId, [vault]);
  }

  async findRarityPDA(bank: PublicKey, mint: PublicKey) {
    return this.findProgramAddress(this.farmProgram.programId, [
      'gem_rarity',
      bank,
      mint,
    ]);
  }

  async findGdrPDA(vault: PublicKey, mint: PublicKey) {
    return this.findProgramAddress(this.farmProgram.programId, [
      'gem_deposit_receipt',
      vault,
      mint,
    ]);
  }

  async findVaultPDA(farm: PublicKey, owner: PublicKey, gemMint: PublicKey) {
    return this.findProgramAddress(this.farmProgram.programId, [
      'vault',
      farm,
      owner,
      gemMint,
    ]);
  }

  async customSendAll(
    reqs: Array<SendTxRequest>,
    opts?: ConfirmOptions
  ): Promise<PromiseSettledResult<string>[]> {
    if (opts === undefined) {
      opts = this.provider.opts;
    }
    const blockhash = await this.connection.getRecentBlockhash(
      opts.preflightCommitment
    );

    let txs = reqs.map((r) => {
      let tx = r.tx;
      let signers = r.signers;

      if (signers === undefined) {
        signers = [];
      }

      tx.feePayer = this.wallet.publicKey!;
      tx.recentBlockhash = blockhash.blockhash;

      signers
        .filter((s): s is Signer => s !== undefined)
        .forEach((kp) => {
          tx.partialSign(kp);
        });

      return tx;
    });

    const signedTxs = await this.wallet!.signAllTransactions!(txs);

    return Promise.allSettled(
      signedTxs.map((tx) => {
        const rawTx = tx.serialize();

        return sendAndConfirmRawTransaction(this.connection, rawTx, opts);
      })
    );
  }
}
