import config from './config'

export default {

    "_id": "62030be4f9ae262d850a18dd",
    "name": "Jelly Babies",
    "imageUrl": "https://res.cloudinary.com/bahdcoder/image/upload/v1646527192/mxYFxaCC_400x400_y6om13.jpg",
    "rewardSymbol": "JELLY",
    "magicEdenUrl": "https://magiceden.io/marketplace/jellybabies",
    "websiteUrl": "https://jellybabiesnft.com",
    "twitterUrl": "https://twitter.com/JellyBabiesNFT",
    "discordUrl": "https://discord.com/invite/wzEfUjmpFw",
    "nftName": "Jelly Baby",
    "nftNamePlural": "Jelly babies",
    "candyMachine": config.CANDY_MACHINE,
    "floorPrice": "3445596884",
    "slug": "agent-apes",
    "decimals": "6",
    "totalItems": "3333",
    "farmAddress": config.FARM_ADDRESS,
    "createdAt": "62030be4f9ae262d850a18dd",
    "rewardMint": "9WMwGcY6TcbSfy9XPpQymY3qNEsvEaYL3wivdwPG2fpp",
}
