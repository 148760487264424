import classnames from "classnames";
import React, { MouseEventHandler, PropsWithChildren, SVGProps } from "react";

export enum ButtonVariant {
  PRIMARY = "primary",
  OUTLINE = "outline",
}

export enum ButtonSize {
  REGULAR = "regular",
  MEDIUM = "medium",
  LARGE = "large",
  SMALL = "small",
}

export function Spinner(
  props: PropsWithChildren<{
    iconProps?: SVGProps<SVGSVGElement>;
  }>
) {
  return (
    <svg
      {...props.iconProps}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx={12}
        cy={12}
        r={10}
        stroke="currentColor"
        strokeWidth={4}
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
}


export interface ButtonProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  className?: string;
  variant?: "primary" | "outline" | "success" | "danger" | "danger-solid";
  size?: "regular" | "medium" | "large" | "small";
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  onClick,
  isDisabled,
  className,
  isFullWidth,
  isLoading,
  size = ButtonSize.REGULAR,
  variant = ButtonVariant.PRIMARY,
}) => {
  const isIconOnly = false;

  const buttonClasses = classnames(
    "flex items-center justify-center rounded-[9px] cursor-pointer transition ease-linear font-semibold  transition ease-linear font-body",
    {
      "px-4 lg:px-8 ": !isIconOnly,
      "text-white": variant !== "danger",
      "h-10": size === ButtonSize.REGULAR && !isIconOnly,
      "h-8 text-sm": size === ButtonSize.SMALL && !isIconOnly,
      "h-12 ": size === ButtonSize.MEDIUM && !isIconOnly,
      "h-14 ": size === ButtonSize.LARGE && !isIconOnly,
      "h-10 w-10": isIconOnly && size === ButtonSize.REGULAR,
      "h-12 w-12": isIconOnly && size === ButtonSize.MEDIUM,
      "h-14 w-14": isIconOnly && size === ButtonSize.LARGE,
      "cursor-not-allowed": isDisabled || isLoading,
      " bg-[#7347ff]": (isDisabled || isLoading) && variant === "primary",
      "bg-secondary-300 cursor-not-allowed":
        (isDisabled || isLoading) && variant === "success",
      "w-full": isFullWidth,
      "bg-[#6333ff] hover:bg-[#7347ff]":
        !isDisabled && !isLoading && variant === "primary",
      "bg-secondary-300": !isDisabled && !isLoading && variant === "success",
      "border border-secondary-200 text-secondary-200": variant === "danger",
      "bg-secondary-200 text-white": variant === "danger-solid",
    },
    className
  );

  let iconContent = null;

  if (isLoading) {
    iconContent = (
      <Spinner
        iconProps={{
          className: classnames("animate-spin -ml-1 mr-3 h-5 w-5", {
            "text-white": variant !== "danger",
            "text-secondary-200": variant === "danger",
          }),
        }}
      />
    );
  }

  return (
    <button
      disabled={isDisabled || isLoading}
      className={buttonClasses}
      onClick={onClick}
    >
      {iconContent}
      {children}
    </button>
  );
};
