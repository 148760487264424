import React from "react";

interface TopBarProps {
  title: string;
  setOpen: (open: boolean) => void;
}

export const TopBar: React.FC<TopBarProps> = ({ title, setOpen }) => {
  return (
    <>
      <div className=" flex justify-between items-center">
        <p className=" font-body text-lg lg:text-xl text-neutral-180">
          {title}
        </p>

        <button
          type="button"
          className=" text-neutral-180 flex items-center w-10 h-10 justify-center"
          onClick={() => {
            setOpen(false);
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </>
  );
};
