import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { WalletContextState } from '@solana/wallet-adapter-react';
import { Connection, PublicKey } from '@solana/web3.js';
import config from './config';
import { programs } from '@metaplex/js';
import Axios from 'axios';
import { BN } from '@project-serum/anchor';

export interface TierConfig {
  rewardRate: BN;
  requiredTenure: BN;
}

const {
  metadata: { Metadata },
} = programs;

export interface INFT {
  pubkey?: PublicKey;
  gemMint: PublicKey;
  mint: PublicKey;
  image: string;
  name: string;
  uri: string;
  tokenIdx?: number;
  isRefreshing?: boolean;
  description: string;
  external_url: string;
  staked?: boolean;
  externalMetadata: any;
  onchainMetadata: {
    mint: string;
    primarySaleHappened: 1 | 0;
    data: {
      creators: {
        address: string;
        verified: 1 | 0;
        share: number;
      }[];
      name: string;
      symbol: string;
      uri: string;
    };
  };
  vault?: {
    account: {
      gemMint: PublicKey;
      locked: boolean;
      rewardA: {
        stakedAt: BN;
        lastRewardsClaimedAt: BN;
        paidOutReward: BN;
        reservedAmount: BN;
        rewardTier: TierConfig;
      };
    };
    publicKey: PublicKey;
  };
  lockDetails?: {
    isLocked: boolean;
    lockPeriodExpired: boolean;
    lockExpiry: Date;
    rewardA: {
      lastRewardsClaimedAt: Date;
      stakedAt: Date;
      rewardTier: {
        requiredTenure: number; // required tenure in seconds
        requiredTenureInDays: number;
        rewardRate: number;
      };
    };
  };
}

export interface ExternalMetadata {
  description: string;
  external_url: string;
  image: string;
  name: string;
  uri: string;
  publicKey: string;
  address: string;
  mint: string;
  staked?: boolean;
}

export class NFT {
  private connection: Connection;

  constructor(private wallet: WalletContextState) {
    this.connection = new Connection(config.CLUSTER_URL);
  }

  public async getNftsInWallet() {
    const tokens = await this.connection.getParsedTokenAccountsByOwner(
      this.wallet.publicKey!,
      {
        programId: TOKEN_PROGRAM_ID,
      }
    );

    const metadata = await Promise.all(
      tokens.value
        .filter((token) => {
          const amount = token.account.data.parsed.info.tokenAmount;
          return amount.decimals === 0 && amount.uiAmount === 1;
        })
        .map((token) => {
          const nftData = {
            pubkey: token.pubkey,
            mint: new PublicKey(token.account.data.parsed.info.mint),
          };

          return this.getNftMetadata(nftData.mint, nftData.pubkey);
        })
    );

    return metadata.filter((data) => data !== null) as unknown as INFT[];
  }

  public async getNftMetadata(mint: PublicKey, pubkey?: PublicKey) {
    try {
      const metadataPDA = await Metadata.getPDA(mint);
      const onchainMetadata = (
        await Metadata.load(this.connection, metadataPDA)
      ).data;

      const externalMetadata = (await Axios.get(onchainMetadata.data.uri))
        .data as any;

      return {
        pubkey,
        mint: new PublicKey(mint),
        onchainMetadata,
        externalMetadata,
      };
    } catch (e) {
      console.log(`failed to pull metadata for token ${mint}`);

      return null;
    }
  }
}
