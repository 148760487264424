import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { BN } from "@project-serum/anchor";

function parseType<T>(v: T): string {
  if (v === null || v === undefined) {
    return "null";
  }
  if (typeof v === "object") {
    if (v instanceof Array) {
      return "array";
    }
    if (v instanceof Date) {
      return "date";
    }
    return "object";
  }
  return typeof v;
}

export function toBN(i: any): any {
  if (typeof i == "number") {
    return new BN(i);
  } else if (i instanceof BN) {
    return i;
  } else if (parseType(i) === "array") {
    const bnArray = [];

    for (const item in i) {
      bnArray.push(toBN(item));
    }

    return bnArray;
  } else if (parseType(i) === "object") {
    const bnObj = {};

    for (const field in i) {
      // @ts-ignore
      bnObj[field] = toBN(i[field]);
    }

    return bnObj;
  }

  return i;
}

export function parseDate(unixTsSec: number | string | BN) {
  const unixBN = toBN(unixTsSec);
  if (unixBN.eq(new BN(0))) {
    return new Date();
  }

  return new Date(unixBN.mul(new BN(1000)).toNumber());
}

export function parseDateToUnix(date: Date) {
  return Math.floor(date.getTime() / 1000);
}

export class AccountUtils {
  public async findProgramAddress(
    programId: PublicKey,
    seeds: (PublicKey | Uint8Array | string)[]
  ): Promise<[PublicKey, number]> {
    const seedBytes = seeds.map((seed) => {
      if (typeof seed == "string") {
        return Buffer.from(seed);
      }

      if ("toBytes" in seed) {
        return seed.toBytes();
      }

      return seed;
    });

    return PublicKey.findProgramAddress(seedBytes, programId);
  }

  async findATA(mint: PublicKey, owner: PublicKey) {
    return Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mint,
      owner
    );
  }

  chunk(items: any[], perChunk = 5) {
    return items.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        (resultArray as any)[chunkIndex] = []; // start a new chunk
      }

      (resultArray[chunkIndex] as any).push(item);

      return resultArray;
    }, []);
  }
}
